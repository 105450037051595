
.show-message-block {
  width: 60rem;
  min-width: 25rem;
  background-color: #ffffff;
  border-radius: 0.9rem;
  .message-container {
    overflow-y: auto;
    height: 100%;
  }
  .show-container{
    display: flex;
    justify-content: space-between;
  }
  .MuiDivider-root {
    height: 0.2rem;
  }
}
