.chat-container {
  position: absolute;
  left: 2rem;
  bottom: 6rem;
  width: 40rem;
  height: 55rem;
  box-shadow: 0px 0px 10px #858585;
  background: white;
  z-index: 1001;

  .chat-header {
    display: flex;
    align-items: center;
    height: 8%;
    font-size: large;
    color: white;
    background-color: #0B655A;
    padding: 0rem 2rem 0rem 1.5rem;

    .MuiIconButton-root {
      margin-right: auto;
      padding: .5rem;
    }

    .close-icon {
      font-size: x-large;
      color: white;
    }
  }

  .chat-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
    height: 85%;
    color: #858585;

    p {
      font-size: x-large;
    }

    .feedback-icon {
      font-size: 20rem;
    }
  }

  iframe {
    width: 100%;
    height: 92%;
    box-sizing: border-box;
    border: none;
  }
}