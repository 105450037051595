@import '../../dashboard.styles.scss';
$cellWidth: 15rem;
$cellHeight: 8em;
$gridGap: 1em;

.personal-info-block {
    grid-area: personal-info;

    .personal-info-container {
        @extend .container;
        padding: 3%;
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax($cellWidth, 1fr));
        grid-template-rows: $cellHeight;
        grid-auto-rows: auto;
        gap: $gridGap;
    }

    .updated-at-text {
        font-size: 1.5em;
        align-self: center;
    }
}

.failed-search {
    @extend .personal-info-container;
    border: 0.2rem solid rgb(182, 44, 44);
}

.edit-personal-info-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($cellWidth, 1fr));
    grid-template-rows: $cellHeight;
    column-gap: 2rem;
    row-gap: 2rem;

    > .info-block {
        margin: 0;
    }
}

.edit-personal-info-action-buttons {
    & > :not(:last-child) {
        margin-left: 1rem;
    }
    margin-top: 2rem;
}

.edit-personal-info-title {
    display: flex;
    align-items: center;
}

.apply-candidacy-btn {
    margin-right: 1rem;
}
