@import '../../../../styles/variables';

.card-container {
  direction: rtl;
  background-color: white;
  border-radius: 1.5rem;
  &--column {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    text-align: center;
  }
  &--row {
    margin: 0 2rem 2rem 0;
  }
  &--manager-mode {
    background-color: #0b655a1f;
  }
}

.card-container__header {
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem 0rem;
  border-bottom: 0.2rem solid rgba(11, 101, 90, 0.1);
  &--row {
    text-align: right;
    padding: 1rem 4rem;
  }
  &--column {
    text-align: center;
  }
}

.card-container__body {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 1rem;
}

.card-container__body--column {
  @extend .card-container__body;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  min-height: 528px;
  padding: 2rem 3rem;
  justify-content: space-between;
  height: 100%;
}

.card-scroll {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  direction: ltr;
  padding:0 10px 0 10px;
}

.disabled {
  background-color: rgba($secondary-accent, 0.2);
}
