@import "../../dashboard.styles.scss";

.files-block {
  grid-area: files;

  .files-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    padding: 1.3125rem 1.3125rem 1.3125rem 0;
    min-width: 60rem;
    box-sizing: border-box;

    .no-files {
      margin-right: 2rem;
      font-size: 2rem;
      text-align: start;
      color: hsl(0, 0%, 20%);
    }
  }

  .files-segmant {
    max-height: 6rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .files-grid {
    margin: auto;
  }
}
