@import '../../dashboard.styles.scss';

.message-block {
    grid-area: messages;
    max-width: 40rem;
    min-width: 20rem;
    overflow-y: hidden;

    .message-container {
        overflow-y: auto;
        max-height: calc(100% - 90px);
        position: absolute;
        @extend .container;
        p {
            overflow-wrap: break-word;
            max-width: 100%;
            font-size: 2.5rem;
        }

        .no-messages {
            margin: 2rem;
            color: hsl(0, 0%, 20%);
            font-size: 2rem;
        }
    }

    .MuiDivider-root {
        height: 0.2rem;
    }
}
