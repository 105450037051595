@font-face {
  font-family: 'Open Sans Hebrew';
  src: local('Open Sans Hebrew'), url(./assets/fonts/opensanshebrew/OpenSansHebrew-Regular.ttf) format('truetype');
}

html {
  overflow: hidden;
}

body {
  direction: rtl;
  margin: 0;
  background-image: url(/src/assets/logos/background-image.png);
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
  background-size: cover;
  font-family: Open Sans Hebrew;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0.7rem;
  height: 1.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  padding: 1rem;
  background: #0b655a23; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #0b655a2a;
  border-radius: 0.9rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0b655a6b; 
}

input, select, textarea, button, code{font-family:Open Sans Hebrew !important;}
