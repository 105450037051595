@import "../common/components/sidebar/sidebar.styles.scss";
@import "../common/components/footer/footer.styles.scss";
@import "../common/components/header/header.styles.scss";
@import '../styles/variables';
$padding: 4rem;

.page {
  height: calc(100vh - #{$appbarHeight + $footerHeight});
  overflow: auto;
  margin-top: $appbarHeight;
  margin-bottom: $footerHeight;
  .page-content {
      padding: 1rem 0;
      margin-left: $padding;
      margin-right: $sidebarWidth + $padding;

    h1 {
      font-weight: 400;
      text-align: right;
      color: $primary-green;
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-loader {
  width: 50%;
  margin-top: 10vh;
}
