@import '../../../styles/variables';
@import '../sidebar/sidebar.styles.scss';

$footerHeight: 6rem;
.footer-container {
    background-color: $primary-green;
    position: absolute;
    height: $footerHeight;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100vw;
    left: 0;
    bottom: 0;
    z-index: 1500;
    * {
        margin: 0 2rem 0 0.2rem;
    }
    .contact-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
    }
    .logos-container {
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-height: 80%;
        }
    }
    .contact {
        color: white;
        font-size: 22px;
        font-weight: bold;
    }
    .info {
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
            color: white;
            font-size: 22px;
            margin-right: 2rem;
        }

        .chat-icon {
            font-size: 40px;
            color: #91D8CE;
            cursor: pointer;
        }

        .access-time-icon {
            @extend .chat-icon;
            cursor: default;
        }
    }
}