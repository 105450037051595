.all-seasons {
    &__buttons {
        display: flex;
        justify-content: space-around;
    }
    &__button{
    border: none;
    cursor: pointer;
    outline: none;
    background: none;
    height: 5rem;
    align-items: center;
    img {
        max-height: 80%;
    }
    &--change-color {
        img {
            filter: invert(30%) sepia(10%) saturate(4438%) hue-rotate(128deg) brightness(94%) contrast(91%);
        }
    }
}
}