@import "../../../../styles/variables";

.message-banner {
  font-size: 1.7rem;
  text-align: start;
  margin: 2rem;

  .message-header {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    color: $primary-green;
    margin-bottom: 1.5rem;

    img {
      margin-left: 2rem;
    }
    .message-main {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
    }
  }

  .message-text {
    color: hsl(0, 0%, 20%);
    word-break: break-word;
  }
}
