@import '../../header/header.styles.scss';
.sidebar-personal-info-container {
    direction: rtl;
    display: flex;
    padding: 4.5rem 1.6rem;
    margin-top: $appbarHeight;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 0;
    .user-name {
        font-size: 2.5rem;
        color: white;
    }
}