.edit-season-page {
    .table-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 1rem 0;
        .filter {
            display: flex;
            width: 20%;
            font-size: 2.5rem;
            margin-bottom: 0;
        }
        .left-side-options {
            display: flex;
            align-items: center;
            & > * {
                margin-right: 1rem;
                &:first-child {
                    margin-left: 2rem;
                }
            }
            justify-content: space-between;
        }
    }
    &__buttons {
        margin-top: 2rem;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}
