.edit-permissions-page {
    .head-container{
        justify-content: flex-end;
        display: flex;
        margin-bottom: 2rem;
    }

    .text-input{
        background-color: white;
    }
}

.select_container {
    .grid {
        margin-top: 3%;
    }
}