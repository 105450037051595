.form-add-season {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    .inputs{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        & > * {
           flex-basis: 45%; 
           margin-bottom: 5rem;
        }
    }
    .buttons{
        align-self: end;
        display: flex;
        flex-flow: row wrap;
        > * {
           flex-basis: 1; 
        }
    }
}