.page {
    &__filter{
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
    &__categories {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 60rem;
        font-size: 2.5rem;
    }
    &__buttons {
        margin-top: 2rem;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .export-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > * {
            margin-bottom: 2rem;
        }
    }
}

.info-logo {
    width: 5rem;
}
