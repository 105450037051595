@import "../../../../styles/variables";

.root {
  direction: rtl;
  text-align: right;
  list-style: none;
  display: flex;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 3%;
  *.MuiTableContainer-root {
    width: 80%;
    margin: 0 auto;
  }
  .MuiTable-root {
    border-collapse: collapse;
    border-style: hidden;
  }
}
.body {
  background-color: rgba(11, 101, 90, 0.1);
  direction: rtl;
}
.root-table {
  *.MuiTableCell-root {
    padding: 16px;
    border-bottom: none;
    font-family: Open Sans Hebrew;
    text-align: center;
  }
  *.MuiTableCell-body {
    color: $primary-green;
    font-size: 16px;
    direction: rtl;
    border-bottom: rgba(11, 101, 90, 0.1) 1px solid;
    border-left: rgba(11, 101, 90, 0.1) 1px solid;
  }
  *.MuiTableCell-head {
    color: $primary-green;
    font-weight: bold;
    font-size: 16px;
    direction: rtl;
  }
}
.current-user {
  background-color: rgba($secondary-green, 0.4);
}
