@import '../../styles/variables';

.table-container {
    background-color: $primary-base;
    border-radius: 15px;
    backdrop-filter: blur(30px);
    box-shadow: 0px 3px 20px #0000000D;
    min-height: 50rem;
}

.buttons-container{
    display: flex;
    direction: ltr;
    padding-top: 1.5rem;
}

.buttons{
    margin-right: 1.5rem;
}
