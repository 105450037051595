@import '../../../../styles/variables';

.job-info-card {
  display: flex;
  flex-flow: column;
  font-size: 1.6rem;
  width: 30%;
  max-width: 250px;
  min-width: 22rem;
  white-space: nowrap;
  padding-top: 1.5rem;

  button {
    font-size: 1.6rem;
    max-width: 250px;
  }

  .job-info-text {
    margin-bottom: 1rem;
    width: 100%;
    text-align: right;

    p {
      width: 100%;
      position: relative;
  
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
  
      .tooltip-text {
        opacity: 0;
        overflow: visible;
        background-color: $primary-green;
        color: white;
        text-align: center;
        border-radius: 1rem;
        padding: .5rem 1rem;
        white-space: normal;
        transition: opacity .3s ease-in-out;
  
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        right: 8rem;
        top: 2.8rem;
      }
    }
  
    p:hover .tooltip-text {
      opacity: 1;
    }
  }
}
