.user-option {
    background-color: #0b655a2f;
    padding: 1rem 0.5rem;
    margin: 1rem 0;
    border-radius: 0.9rem;
    display: flex;
    align-items: center;
    height: 4rem;
    cursor: pointer;
    outline: none;
    border: none;
    &:hover {
        background-color: #0b655a8a;
    }
    span {
        font-size: 1.6rem;
        margin: 1rem;
        vertical-align: middle;
    }
}