.filter__bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  font-size: 2.5rem;
  width: 100%;
}

.autocomplete {
  *.MuiInputBase-root {
    font-size: 2.5rem;
  }
}

.listbox-autocomplete {
  font-size: 2.5rem;
  font-family: Open Sans Hebrew;
}

.root-autocomplete {
  background-color: white;
  border-radius: 0.9rem;
  padding: 1rem 1.5rem;
  *.MuiFormControl-root {
    align-items: center;
    width: 30rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  *.MuiInput-formControl {
    margin: 0;
  }
  *.MuiInputLabel-formControl {
    position: static;
    font-size: 2.5rem;
    font-family: Open Sans Hebrew;
    transform: none;
  }
  *.MuiAutocomplete-endAdornment {
    position: static;
  }
  *.MuiInput-root {
    padding-right: 0 !important;
  }
}

.basic-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.auto-complete-grid {
  width: 45%;
}
