.priority {
    height: 100%;
    width:auto;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-self: center;
    & > div {
        flex: 0 0 auto;
        height: 4rem;
        display: flex;
        margin: 0 0 2rem 2rem;
        flex-direction: row;
        align-items: center;
        span {
            align-self: center;
            margin: 0 0 0 2.2rem;
            font-size: 1.8rem;
        }
    }
}