.row {
  display: flex;
  justify-content: space-between;
}

.name {
  padding-bottom: 2%;
  padding-left: 1%;
}

.data {
  width: 65%;
  padding-bottom: 2%;
}


.array-text-inputs {
  height: 20rem;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  & > div {
      flex-basis: 100%;
      margin-bottom: 0;
  }
  & > * > .text-input {
      padding: 0.6rem 1rem 0.6rem 0;
  }
}
