@import '../../styles/variables';

.error-container{
    margin: 0 auto;
    margin-top:8rem;
    background-color: white;
    border-radius: 0.9rem;
    padding: 2rem 2.5rem;
    backdrop-filter: blur(30px);
    box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.418);
    display: flex;
    flex-direction: column;
    width: 50%;
    font-weight: bolder;
    color:  $primary-green;
}
.massage{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 40px;
    *.MuiSvgIcon-root {
        font-size: 55px;
    }
}

.description{
    padding: 2rem 2.5rem;
    font-size: 25px;
}