.new-content-container {
  display: flex;
  flex-flow: column;
  padding: 1.5rem 2.5rem;
  margin-right: 4rem;
  text-align: right;
  background-color: #ffffff;
  min-width: 25rem;
  width: 55rem;
  border-radius: 0.9rem;
  .save-button {
    margin-top: auto;
    text-align: left;
  }
}