@import './styles/variables';

.App {
  text-align: center;
  direction: 'rtl';
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
  font-size: 62.5%;
  position: relative;
  min-height: 100%;
}

.not-found {
  width: 100%;
  font-size: 2.2rem;
  color: $primary-green;
}