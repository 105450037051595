@import '../../../styles/variables';

.header-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  .question-mark {
    padding-top: 8px;
    text-align: center;
    width: 40px;
    height: 32px;
    border-radius: 50px;
    background-color: #0b655a80;
    border: none;
    outline: none;
  }
  .new-question-header {
    margin-right: 2rem;
    color: $primary-green;
    font-size: 25px;
    font-weight: bold;
  }
}

.answer-header {
  padding: 1.5rem;
  color: $primary-green;
  font-size: 25px;
}
.input {
  width: 100%;
  height: 15rem;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  border-radius: 0.9rem;
  background-color: #0b655a0f;
  padding: 1.2rem 2.5rem 1.2rem !important;
  overflow-y: auto;
  box-sizing: border-box;
  resize: none;
  &.MuiInputBase-input {
    font-size: 1.6rem;
  }
  > .MuiInputBase-inputMultiline {
    font-size: 1.6rem;
    padding: 0;  
  }
}
