@import '../../../styles/variables';

#backdrop {
    z-index: 100000;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress {
    color: $primary-green !important;
}

.loadingText {
    user-select: none;
    margin-top: 1em !important;
    font-size: 3rem !important;
    color: $primary-green;
}