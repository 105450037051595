@import '../../../styles/variables';

.editor-table {
  max-height: calc(100vh - 39rem);
  direction: rtl;
  text-align: right;

  .body {
    background-color: rgba(11, 101, 90, 0.1);
    direction: rtl;
  }

  .editor-table-root {
    border-spacing: 0 10px;
    overflow-x: initial;
    thead th {
      position: sticky;
      top: 0px;
      z-index: 1;
    }
    background-color: white;
    border-radius: 1.5rem;
    
    *.MuiTableCell-body {
      box-sizing: border-box;
      &:not(:last-of-type) {
        border-left: 1px solid #c8dcd9;
      }
      min-width: 18rem;
      font-family: Open Sans Hebrew;
      color: $primary-green;
      font-size: 1.6rem;
      text-align: right;
      direction: rtl;
      margin-bottom: 16px;
    }
    *.MuiTableCell-head {
      color: $primary-green;
      font-weight: bold;
      background-color: white;
      font-size: 1.4rem;
      font-family: Open Sans Hebrew;
      direction: rtl;
      text-align: right;
      white-space: nowrap;
    }
    *.MuiTableRow-root {
      background-color: #e4eeec;
      border-radius: 1.5rem;
    }

    .hover {
      &:hover {
        cursor: pointer,
      }
    }
  }
}
