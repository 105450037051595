@import '../../../../styles/variables';

.question-block {
    height: 7.5rem;
    border-bottom: 0.2rem solid rgb(206, 206, 206);
    box-sizing: border-box;
    button {
        padding: 1rem;
        height: inherit;
        background: transparent;
        width: 100%;
        border: none;
        cursor: pointer;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &:hover {
        background-color: #0B655A1A;
    }
    &-selected {
        @extend .question-block;
        background-color: #0B655A1A;
    }
}

.question-mark-logo {
    background-color: #0B655A80;
    width: 4.5rem;
    height: 4.5rem;
    padding: 1rem;
    border-radius: 50%;
}

.left-arrow-logo {
    @extend .question-mark-logo;
    background-color: $secondary-green;
}

.question-content {
    height: 100%;
    width: 60%;
    font-size: 2rem;
    overflow-y: auto;
}