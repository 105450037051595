.job-content-section-small {
  min-width: 22rem;
  max-height: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.job-content-section-big {
  min-width: 22rem;
  max-height: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.job-content-card-header {
  white-space: nowrap;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: right;
}

.card {
  height: min-content;
  min-height: 15rem;
  padding-top: 1.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  white-space: nowrap;
}

.card-text {
  max-width: 220px;
  height: 100%;
  padding: .5rem 1.5rem;
  background: rgba(11, 101, 90, 0.1);
  overflow-y: scroll;
  overflow-wrap: break-word;
  white-space: normal;
}

.card-text-shadow {
  height: 100%;
  padding: .5rem 1.5rem;
  background: rgba(11, 101, 90, 0.1);
  overflow-y: scroll;
  overflow-wrap: break-word;
  white-space: normal;
  box-shadow: rgba(50, 50, 93, 0.25) 0 1rem 1rem -0.3rem, rgba(0, 0, 0, 0.3) 0 0.5rem 2rem -0.5rem;
}

.card__text {
  font-size: 1.8rem;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // p:hover {
  //   overflow: visible;
  //   word-wrap: normal;
  // }

  *.MuiFormControl-root{
    width: 100%;
    height: 100%;
  }
}

