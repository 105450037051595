@import '../../../../styles/variables';

.filter__item {
  display: block;
  padding: 0rem 2rem;
  color: $primary-green;
  border: 0px;
  cursor: pointer;

  &--selected {
    font-weight: bold;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-image: linear-gradient(73deg, $secondary-green 0%, $light-green 100%) 1 0%;
  }
}