@import '../../dashboard.styles.scss';

.candidacies-state-block {
  grid-area: candidate-status;

  .candidacies-state-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 0rem 4rem;
    min-width: 60rem;
    box-sizing: border-box;

    .candidacies-progress-headline {
      display: flex;
      position: relative;
      margin-right: 17rem;
      height: 3rem;
      margin-bottom: 1rem;
      margin-left: 19rem;

      p {
        position: absolute;
        font-size: 1.5rem;
        display: block;
        width: 11.5rem;
        color: #858585;
      }
    }

    .no-candidacies {
      margin-bottom: 2rem;
      font-size: 2rem;
      text-align: start;
      color: hsl(0, 0%, 20%);
    }
  }
  .candidacies-state-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
    text-align: right;
  }
}
