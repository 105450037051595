$heightOfFirstLayer: 60rem;
$widthOfFirstLayer: 60rem;

.dashboard-page {
    display: grid;
    height: auto;
    position: relative;
    grid-template-columns: minmax($widthOfFirstLayer, 10fr) 3fr;
    grid-auto-rows: auto;
    grid-gap: 5rem;


    grid-template-areas: 
        "personal-info messages"
        "candidate-status messages"
        "files messages";
}


.container {
    background-color: white;
    border-radius: 0.9rem;
    backdrop-filter: blur(30px);
    border: 1px solid #eee;
}

