@import '../../../styles/variables';

$sidebarWidth: 36rem;
.sidebar {
    width: $sidebarWidth;
    max-height: calc(100% - 6rem);
    opacity: 0.75;
    background: transparent linear-gradient(198deg, $light-green 0%, $secondary-green 100%) 0% 0% no-repeat padding-box;
}

.nav-link {
    text-decoration: none
}

.nav-button {
    padding: 0%;
}

.manager-sidebar {
    margin: 2rem;
    * {
        color: $primary-green;
        font-size: 2rem;
    }
    border-radius: 0.9rem;
    background-color: #90E9C8;
}

.manager-menu {
    text-align: right;
    margin: auto 2rem 0;
    color: white;
    font-size: 2.2rem;
    font-weight: bold;
}