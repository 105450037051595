.snackbar {
    &.MuiSnackbar-root{
        z-index: 1000001;
    }
    .snackbar-error-content {
        padding: 1rem 2rem;
        border-radius: .5rem;
        background-color: #d36161;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        > span {
            margin-left: 2rem;
            font-size: 1.6rem;
            vertical-align: middle;
        }
    }
}
