@import '../../../../styles/variables';

input[type=checkbox] {
    color: red;
}

.max-height {
    max-height: 265px;
    overflow-y: auto;
}

.container {
    display: flex;
    justify-content: start;
    position: relative;
    margin-bottom: 3%;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container > input {
    cursor: pointer;
}

.container > input:disabled {
    cursor: auto;
}

.wrapper {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: start;
}

.checkbox {
    color: $primary-green !important;
}

.disabledCheckbox{
    color: $secondary-base !important;
}

.checkmark {
    margin-left: 2%;
    font-size: 1.5rem;
}