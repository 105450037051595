@import '../../../../styles/variables';

.details-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.2rem;
    span {
        font-size: 1.6rem;
        align-self: flex-start;
        color: $primary-green;
        margin-bottom: 1rem;
    }
    &__container {
        display: flex;
        background-color: #0b655a2d;
        align-items: center;

        border-radius: 0.9rem;
        padding: 0.5rem 0.5rem;
    }
}
