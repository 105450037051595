@import '../../../styles/variables';
@import '../sidebar/sidebar.styles.scss';

$padding: 4rem;
$appbarHeight: 10rem;
.app-bar {
    z-index: 10001 !important;
}

.header {
    display: flex;
    height: $appbarHeight;
    justify-content: space-between;
    .header-inputs {
        display: flex;
        margin-left: 50px;
        align-items: center;
        >img {
            margin-right: 4rem;
        }
        >div {
            margin-left: 4rem
        }
    }
    .header-logo {
        display: flex;
        align-items: center;
        h1 {
            color: $primary-green;
            font-size: 27px;
        }
    }
}

.logos-container {
    display: flex;
    width: $sidebarWidth;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
    margin-left: 24px;
    img {
        height: 70px;
    }
    .yesodot {
        align-items: center;
        height: 60px;
    }
}