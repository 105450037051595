@import '../../../../styles/variables';

.container-header{
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        font-size: 2rem;
        color: $primary-green;
    }
    .buttons {
        button:nth-child(2) {
            margin-right: 1rem;
        }
    }
}