@import '../../../styles/variables';

.input-container {
    display: flex;
    flex-direction: column;
    .text-input-header {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        text-align: right;
        color: $primary-green;
    }
    *>.MuiInputBase-input {
        font: initial;
    }
}

.labels {
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: 450;
    text-align: right;
}
