@import '../../../../styles/variables';

.select-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    *:focus {
        outline: none;
    }
}

.autocomplete {
    *.MuiInputBase-root{
      font-size: 1.6rem !important;
    }  
}
  
.listbox-autocomplete {
  font-size: 1.6rem !important;
  font-family: Open Sans Hebrew;
}

.select {
    box-shadow: rgba(50, 50, 93, 0.25) 0 1rem 1rem -0.3rem, rgba(0, 0, 0, 0.3) 0 0.5rem 2rem -0.5rem;
    border: none;
    overflow: hidden;
    -moz-appearance: none;
    background-size: 1.6rem;
    background-position-x: 5%;
    padding: 1rem 0.5rem;
    cursor: pointer;
    min-height: 54px;
    background-color: #0b655a2d;
    color: #000;

    *.MuiFormControl-root{
        align-items: center;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      *.MuiInput-formControl{
        margin: 0;
      }
      *.MuiInputLabel-formControl {
        position: static;
        font-size: 2rem;
        font-family: Open Sans Hebrew;
        transform: none;
      }
      *.MuiAutocomplete-endAdornment{
        position: static;
      }
      *.MuiInput-root{
        padding-right:0 !important;
      }
}

.disabled {
    background-color: rgba(209, 192, 192, 0.533);
    cursor: default;
}