@import '../../../styles/variables';

.new-message-header {
  color: $primary-green;
  font-size: 25px;
  margin-bottom: 1.5rem;
}

.message-date {
  background-color: #0b655a0f;
  border-radius: 0.9rem;
  max-width: 100%;
  height: 3rem;
  padding: 1rem;
  display: flex;
  color: $primary-green;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  text-align: start;
  img {
    margin-left: 2rem;
  }
}

.input {
  width: 100%;
  height: 15rem;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  border-radius: 0.9rem;
  background-color: #0b655a0f;
  padding: 1.2rem 2.5rem;
  margin-bottom: 1.5rem;
  resize: none;
  &.MuiInputBase-input {
    font-size: 1.6rem;
  }
}

.date-input {
  width: 100%;
  height: 5rem;
  outline: none;
  border: none;
  border-radius: 0.9rem;
  background-color: #0b655a0f;
  margin-bottom: 1.5rem;
  padding: 1.2rem 2.5rem;
}
