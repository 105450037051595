@import '../../../styles/variables';

.input-container {
    display: flex;
    flex-direction: column;
    .text-input-header {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        text-align: right;
        color: $primary-green;
    }
    *>.MuiInputBase-input {
        font: initial;
    }
}

.text-input {
    box-shadow: rgba(50, 50, 93, 0.25) 0 1rem 1rem -0.3rem, rgba(0, 0, 0, 0.3) 0 0.5rem 2rem -0.5rem;
    border-radius: 1.5rem;
    margin: 0 0rem 0 2.2rem;
    padding: 0.6rem 0.5rem;
}

.inverted {
    background-color: #0b655a2d;
}

.font {
    &.MuiInputBase-input {
        font-size: 2rem;
    }
}

.disabled {
    background-color: rgba(209, 192, 192, 0.533);
    cursor: default;
}