$pictureSize: 10rem;
$smallSize: 5rem;

.user-avatar-picture {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  border-radius: 50%;
  width: $pictureSize;
  height: $pictureSize;
  background-color: white;
  margin: 2rem;
  cursor: pointer;

  &.small-image {
    width: $smallSize;
    height: $smallSize;
  }

  img {
    border-radius: 50%;
    object-fit: fill;
    height: 100%;
  }
}
