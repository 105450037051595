@import '../../../styles/variables';

.select-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    span {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        text-align: right;
        color: $primary-green;
    }
    *:focus {
        outline: none;
    }
    *.MuiIconButton-label {
        margin-bottom: 0rem;
        color: #000;
    }
    *.MuiAutocomplete-popper {
        position: absolute;
        top: 185px;
    }
}

select {
    box-shadow: rgba(50, 50, 93, 0.25) 0 1rem 1rem -0.3rem, rgba(0, 0, 0, 0.3) 0 0.5rem 2rem -0.5rem;
    border: none;
    overflow: hidden;
    -moz-appearance: none;
    background: url('../../../../assets/logos/select-arrow.svg') no-repeat left white;
    background-size: 1.6rem;
    background-position-x: 5%;
    padding: 1rem 0.5rem;
    border-radius: 1.5rem;
    font-size: 1.2rem;
    cursor: pointer;
    color: $primary-green;
}

.inverted {
    background-color: #0b655a2d;
    color: #000;
    padding: 1rem 0.5rem;
    font-size: 1.6rem;
    option {
        color: #000;
    }
}

.disabled {
    background-color: rgba(209, 192, 192, 0.533);
    cursor: default;
}

.inverted-select {
    padding: 1.5rem 0.5rem;
}

option {
    color: $primary-green;
}