@import '../../../styles/variables';

.dialog-root {
    z-index: 10002 !important;
    border-radius: 0.9rem;
    .MuiDialog-container{
        height: calc(100% - 60px);
    }

    .MuiDialog-paper{
        width: 60rem;
        padding: 1rem 2rem;
    }

    &--table {
        .MuiDialog-paper{
            width: 150rem;
        } 
        .MuiDialog-paperWidthSm {
            max-width: 150rem;
        }
    }

    &--job {
        .MuiDialog-paper{
            width: 115rem;
        } 
        .MuiDialog-paperWidthSm {
            max-width: 115rem;
        }
    }

    &--apply-candidacy {
        .MuiDialog-paper{
            width: 61rem;
        } 
        .MuiDialog-paperWidthSm {
            max-width: 70rem;
        }
    }
}
.dialog-content {
    width: 100%;
    height: 100%;
    margin: 2rem 0;
}

.dialog-header {
    display: flex;
    height: 10%;
    align-items: center;
    justify-content: space-between;
    &__content {
        display: flex;
        align-items: center;
    }
    .dialog-title {
        font-size: 2.5rem;
        color: $primary-green;
    }
    .avatar-dialog-title {
        font-size: 2.5rem;
        font-weight: bold;
        color: $primary-green;
    }
}
