.job-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
&__filter-bar {
  width: 75%;
}
}
.positions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 2rem;
  max-height: calc(100vh - 36rem);

&__position-list {
  flex-basis: 75%;
  display: flex;
  flex-flow: column nowrap;
  direction: ltr;
  overflow-y: auto;
  min-width: min-content;
}

&__desire-jobs-container {
  flex-basis: 20%;
  margin-right: 5rem;
}
}

.button--large {
  width: 100%;
  padding: 0.5rem 0rem;
  margin: 3rem 0rem 4rem 0rem;
  justify-self: flex-end;
}

.job-flex-item-wrap {
  width: 100%;
}

.positions-not-found {
  font-size: 2rem;
  padding: 15%;
}