@import '../../../../styles/variables';

.display-field {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.5rem 0rem;
    color: $primary-green;
    background: rgba(11, 101, 90, 0.1);
    border-radius: 3%;
    &--row {
        text-align: right;
        padding: 1rem 4rem;
    }
    &--column {
        text-align: center;
    }
}

.button {
    width: 45px;
    height: 45px;
    display: 'flex';
    align-items: 'center';
    flex-wrap: 'wrap';
}

.image-grid-item {
   padding: 0px !important;
}

.core-image {
    width: 55px;
    height: 55px;
}
