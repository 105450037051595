@import '../../styles/variables';

.apply-candidacy-page {
    background-color: white;
    border-radius: 0.9rem;
    padding: 2rem 2.5rem;
    backdrop-filter: blur(30px);
    z-index: 1;
    .buttons{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        & :first-child {
            margin-left: 1rem;
        }
    }
}

.candidacy-details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.section {
    width: 100%;
    min-height: 100%;
    padding: 0.5%;
}

.info {
    display: flex;
    justify-content: space-between;
}

#backdrop {
    z-index: 1000;
}

.progress {
    color: $primary-green !important;
}
