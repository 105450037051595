@import '../../../styles/variables';

.question-answer-item {
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem;
  min-width: 25rem;
  border-bottom: 0.19rem solid #34343433;
  &:last-child {
    border-bottom: none;
  }

  .question-answer {
    display: flex;
    flex-flow: column nowrap;

    .header-container {
      display: flex;
      flex-direction: row;
      align-items: baseline;
    
      .question-mark {
        margin: 0;
        text-align: center;
        min-width: 40px;
        height: 32px;
        border-radius: 50px;
        background-color: #0b655a80;
        border: none;
        outline: none;
      }
      .question {
        margin: 0 2rem;
        color: $primary-green;
        font-size: 2.2rem;
        font-weight: bold;
      }
    }
    
    .answer {
      padding: 1rem 0;
      font-size: 1.6rem;
      text-align: right;
      word-wrap: break-word;
    }
  }

  .trash {
    margin-right: auto;
    min-width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #66cfc2;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all .2s ease-in-out;
    &:hover {
      transform: scale(1.1, 1.1);
      box-shadow: 1px 1px 4px 2px rgba(77,77,77,.13)
    }
  }
}
