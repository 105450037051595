@import '../../../../styles/variables';

.sidebar-item {
    height: 6.5rem;
    &.MuiButtonBase-root:hover {
        display: flex;
        justify-content: center;
        background-color: #0b655a54;
    }
}

.selected-item {
    @extend .sidebar-item;
    &.MuiButtonBase-root {
        background-color: #0b655a4b;
        border-left: 1rem solid $primary-green;
    }
}

.text {
    color: rgb(255, 255, 255);
    font-size: 20px;
    padding: 1.5em 2rem;
    text-align: right;
}

.sidebar-icon {
    &.MuiListItemIcon-root {
        text-align: center;
        display: inline;
        width: 5rem;
    }
}