.edit-content-page {
  .filter {
    display: flex;
    width: 30%;
    font-size: 2rem;
    margin-bottom: 0;
  }
  .container-blocks {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100vh - 33rem);
    min-height: 55rem;
  }
}
