@import '../../../../styles/variables';

.desire-job { 
  background: rgba(11, 101, 90, 0.1);
  box-shadow: 0px 3px 20px #0000000D;
  border-radius: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 1rem 0rem;
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
  background: rgba(11, 101, 90, 0.1);
  border-radius: 2rem;
  width: 100%;
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 0.2rem 0.4rem rgba(black, 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(black, 0.2);
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }
}

.desire-job--text {
  color: $primary-green;
  width: 100%;
  align-self: center;
  border: none;
  &,
    &:link,
    &:visited {
        text-decoration: none;
        padding: 0.5rem;
        display: inline-block;
        border-radius: 10rem;
        transition: all .2s;
        position: relative;
        font-size: 1.6rem;
        border: none;
    }
}

.btn {
  border: 0px;
  border-radius: 2rem;
  background: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(black, 0.2);
  }
}

.icon {
  width: 1.8rem;
  height: 2rem;
  &:hover {
    cursor: pointer;
  }
}

.disabled {
  background-color: rgba($secondary-accent, 0.2);
}