$stepCircleRadius: 2.5rem;

@keyframes stepper-connector {
  from {
    width: 0%;
  }
}

.candidacy-progress-item {
  display: flex;
  // height: $stepCircleRadius;
  margin-bottom: 4rem;

  p {
    font-size: medium;
    min-width: 10rem;
    max-width: 10rem;
    align-self: center;
  }
}

.stepper-container {
  align-self: center;
  position: relative;
  height: 0.75rem;
  width: 100%;
  background: #f2f5fa;

  .step-date {
    position: absolute;
    top: 2.5rem;
    font-size: small;
    min-width: 6rem;
    max-width: 6rem;
  }

  .step-circle {
    height: $stepCircleRadius;
    width: $stepCircleRadius;
    box-sizing: border-box;
    background: #f2f5fa;
    border-radius: 50%;
    position: absolute;
    top: -0.8rem;

    &--success {
      @extend .step-circle;
      background: #00962a;
      border: 0.5rem solid #91d8ce;
      right: 5rem;
    }

    &--failure {
      @extend .step-circle;
      background: #d26464;
      border: 0.5rem solid #dfb2b2;
      right: 10rem;
    }
    &--disabled {
      @extend .step-circle;
      background: #7b7b7b;
      border: 0.5rem solid #d6d6d6;
      right: 10rem;
    }
  }

  .step-connector {
    height: 100%;
    animation-name: stepper-connector;
    animation-duration: 3s;
    border-radius: 1rem;

    &--success {
      @extend .step-connector;
      background: transparent linear-gradient(90deg, #00962a 0%, #66cfc2 100%)
        0% 0% no-repeat padding-box;
    }

    &--failure {
      @extend .step-connector;
      background: transparent linear-gradient(90deg, #d26464 0%, #66cfc2 100%)
        0% 0% no-repeat padding-box;
    }

    &--disabled {
      @extend .step-connector;
      background: transparent linear-gradient(90deg, #7b7b7b 0%, #d6d6d6 100%)
        0% 0% no-repeat padding-box;
    }
  }
}
