.info-block{
    text-align: right;
    margin: 2rem 2rem;
    .header-span {
        font-size: 1.5rem;
        color: #858585;
    }
    .header-help-icon {
        color: #858585;
        font-size: 20px;
        margin-right: 5px;
    }
    .info-span {
        font-size: 1.7rem;
        overflow-wrap: break-word;
    }
    .edit-input {
        height: 2.5rem;
    }
    .edit-span {
        font-size: 1.5rem;
        color: #858585;
        white-space: pre;
    }
}
