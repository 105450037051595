@import '../../styles/variables';

.qa-page {
    display: flex;
    width: 100%;
    .questions-block:first-child {
     margin-left: 5rem;   
    }
    height: calc(100vh - 40rem);
    min-height: 55rem;
}

.questions-block {
    display: flex;
    flex-flow: column nowrap;
    width: 50%;
    min-width: 25rem;
    & > div {
        margin-top: 1rem;
    }
    & > span { 
        align-self: start;
        font-size: 1.8rem;
        color: $primary-green;
        font-weight: bold;
    }
}

.questions {
    background-color: #ffffff;
    box-shadow: rgba(50, 50, 93, 0.25) 0 1rem 1rem -0.3rem, rgba(0, 0, 0, 0.3) 0 0.5rem 2rem -0.5rem;
    border-radius: 0.9rem;
    height: 100%;
    overflow-y: auto;
}
.answers {
    @extend .questions;
    padding: 2rem;
}

.answer-content {
    padding: 1rem;
    background-color: #0B655A0F;
    font-size: 2rem;
    border-radius: 0.9rem;
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;
    text-align: right;
}