@import "../../../../styles/variables";

.grid-contanier {
  width: 50%;
  padding-bottom: 2%;
}

.grid-item {
  padding: 25%;
}

.title {
  color: $primary-green;
  font-weight: bold;
  font-size: 16px;
  direction: rtl;
}

.autocomplete {
  *.MuiInputBase-root{
    font-size: 2rem;
  }  
}

.listbox-autocomplete {
  font-size: 2rem;
  font-family: Open Sans Hebrew;
}

.root-autocomplete {
  box-shadow: rgba(50, 50, 93, 0.25) 0 1rem 1rem -0.3rem, rgba(0, 0, 0, 0.3) 0 0.5rem 2rem -0.5rem;
  background-color: white;
  border-radius: 0.9rem;
  padding: 1rem 1.5rem;
  *.MuiFormControl-root{
    align-items: center;
    width: 20rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  *.MuiInput-formControl{
    margin: 0;
  }
  *.MuiInputLabel-formControl {
    position: static;
    font-size: 2rem;
    font-family: Open Sans Hebrew;
    transform: none;
  }
  *.MuiAutocomplete-endAdornment{
    position: static;
  }
  *.MuiInput-root{
    padding-right:0 !important;
  }
}
