@import "../../../styles/variables";

.button {
  height: 4rem !important;
  padding: 0.3rem 1.3rem !important;
  border-radius: 3rem !important;
  font-family: 'Open Sans Hebrew' !important;
  border: none;
  outline: none;
  line-height: 4rem;
  cursor: pointer;
  text-align: center;
  color: $primary-base !important;
  font-size: 2rem;
  background: transparent
    linear-gradient(90deg, $secondary-green 0%, $light-green 100%) 0% 0%
    no-repeat padding-box;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  max-width: 19rem;

  &-small {
    height: 2.5rem !important;
    line-height: 1.5rem;
    padding: 0.66rem 2rem !important;
    font-size: 1.5rem;
  }

  &-fullsize {
    padding: 0rem !important;
    height: 100% !important;
    width: 100%;
  }

  &-hollow {
    background: none;
    color: $primary-green !important;
    border: 0.1rem solid $primary-green;
  }

  &-disabled {
    background: transparent linear-gradient(73deg, #444444 0%, #919191 100%) 0%
      0% no-repeat padding-box;
    cursor: initial;
  }

  &:hover:enabled {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(black, 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(black, 0.2);
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }
  .image-button-container {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    height: 100%;
    span {
      white-space: nowrap;
      font-size: 1.5rem;
      margin-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
